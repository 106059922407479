<template>
  <div class="ysxq">
    <!-- 医生简介 -->
    <div class="ysxq-header">
      <img
        src="@/assets/img/yisheng.png"
        v-if="doctor.doctorPhoto == null || doctor.doctorPhoto == ''"
        class="doctor-img"
        alt=""
      />
      <img :src="doctor.doctorPhoto" v-else class="doctor-img" alt="" />
      <div class="d-flex-column d-flex-left doctor-desc">
        <div style="text-align: left">
          <span style="font-size: 0.42rem">{{ doctor.bizDoctorName }}</span>
          <span style="margin-left: 0.4rem">{{ doctor.doctorLevel }}</span>
        </div>
        <div>
          <span style="font-size: 0.4rem">{{
            doctor.bizDeptName.replace(doctor.bizDoctorName, "")
          }}</span>
          <span style="margin-left: 0.5rem; color: #afafaf"
            >已预约量：<b style="color: #2ea5ff"> {{ appointed }} </b></span
          >
        </div>
      </div>
    </div>
    <van-collapse
      v-model="doctorProfile"
      style="text-align: left"
      @click="open = !open"
    >
      <van-collapse-item title="医生简介" name="1">
        {{ doctor.doctorProfile || "暂无医生简介" }}</van-collapse-item
      >
    </van-collapse>
  </div>
</template>

<script>
export default {
  created() {
    this.doctor = JSON.parse(decodeURIComponent(this.$route.query.doctor));
  },
  props: {
    appointed: {
      type: Number,
    },
  },
  data() {
    return {
      doctorProfile: [],
      open: false,
    };
  },
  computed: {
    doctorImg() {
      return this.doctor.PortraitUrl || "../assets/img/yisheng.png";
    },
  },
};
</script>

<style scoped>
.ysxq {
  width: 9.5rem;
  margin: 10px auto;
  border-radius: 10px;
  overflow: hidden;
}
.ysxq-header {
  background-color: #fff;
  padding: 12px 15px;
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 0.4rem;
}
.doctor-desc {
  margin-left: 5px;
}
.doctor-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
