<template>
  <div :style="{ marginBottom: '2rem' }">
    <doctor-detail
      ref="doctorDetail"
      :doctor="doctor"
      :appointed="appointed"
    ></doctor-detail>
    <div class="d-flex-column">
      <div class="yyqr-uni-list-jzrq">
        <van-cell-group
          style="
            width: 9.5rem;
            margin: auto;
            border-radius: 10px;
            overflow: hidden;
          "
        >
          <van-cell title="就诊日期" style="text-align: left" is-link>
            <template #default>
              <span style="color: #999">{{ $route.query.clinicDateText }}</span>
            </template>
          </van-cell>
          <van-cell
            title="就诊人"
            is-link
            style="text-align: left"
            @click="openChoose()"
          >
            <template #default>
              <span style="color: #323233">{{ chooseItem.name }}</span>
            </template>
          </van-cell>
          <van-cell title="预约时间段" style="text-align: left">
            <template #label>
              <div
                class="times d-flex-row"
                v-for="(item, index) in data3105"
                :index="index"
                :key="index"
                @click="changeSchedule(index)"
              >
                <div
                  class="schedule"
                  :class="{
                    active: index == currentIndex,
                  }"
                >
                  <div class="text" style="font-size: 0.35rem">
                    {{ item.dutyStartTime.slice(0, 5) }} -
                    {{ item.dutyEndTime.slice(0, 5) }}
                  </div>
                  <div class="text" style="font-size: 0.35rem; margin-top: 6px">
                    余 {{ item.dutyRemainedNum }}
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
          <van-cell title="付款信息" style="text-align: left" center>
            <template #title>
              <span>医院挂号费</span>
            </template>
            <template #default>
              <span style="color: #ff0000">￥{{ clinicFee / 100 }}*1</span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}
        </span>
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />
        添加就诊人
      </div>
    </van-popup>
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3>预约挂号需知</h3>
      </template>
      <template #default>
        <div class="dialog-message">
          温馨提示：请您当日及时就诊，若不能就诊，请您及时办理退号，祝您早日康复
        </div>
        <div class="dialog-message">
          退号提示： 1.自助机挂号的到挂号处退号 2.网上预约的可以直接在手机端退号
        </div>
        <van-button class="cancle-btn" @click="showDialog = false"
          >取消</van-button
        >
        <van-button
          type="primary"
          :loading="btnLoading"
          class="confirm-btn"
          @click="submit"
          >确认</van-button
        >
      </template>
    </van-dialog>
    <van-dialog
      v-model="showDialog1"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3>温馨提示</h3>
      </template>
      <template #default>
        <p class="dialog-message">同一就诊日已经预约过该医生，请勿重复预约！</p>
        <van-button class="cancle-btn" @click="showDialog1 = false"
          >关闭</van-button
        >
        <van-button type="primary" class="confirm-btn" @click="selectAppoint"
          >查看我的预约</van-button
        >
      </template>
    </van-dialog>
    <van-dialog
      class="dialog"
      v-model="overflowShow"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
      :overlayStyle="{ backgroundColor: 'rgba(0,0,0,0.5)' }"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>暂无就诊人信息，请添加就诊人</p>
      <van-button class="cancle-btn" @click="overflowShow = false"
        >取消</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="addPatient"
        >确认</van-button
      >
    </van-dialog>
    <div class="info-notice">注意：如需医保支付，请前往医院现场开单</div>
    <van-submit-bar :price="clinicFee">
      <template #button>
        <van-button
          type="primary"
          color="#4AC691"
          @click="submitData"
          class="button-confirm"
          >确认预约</van-button
        >
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
import doctorDetail from "@/components/doctor-detail.vue";
import { Toast } from "vant";

export default {
  components: { doctorDetail },
  data() {
    return {
      currentIndex: 0,
      dateList: [1, 1, 1],
      schedules: [],
      clinicDateText: "",
      chooseItem: "",
      date: null,
      deptId: "",
      showPopup: false,
      showDialog: false,
      overflowShow: false,
      list: [],
      isEmpty: false,
      doctor: {},
      LabelType: "",
      personId: "",
      dateEnum: {
        Mon: "周一",
        Tue: "周二",
        Wed: "周三",
        Thu: "周四",
        Fri: "周五",
        Sat: "周六",
        Sun: "周日",
      },
      apply_notice: {},
      appointPayState: sessionStorage.getItem("appointPayState"),
      registFee: "",
      clinicFee: 0,
      seekFee: "",
      showDialog1: false,
      data3105: [],
      btnLoading: false,
      appointed: 0,
    };
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.overflowShow = true;
        return;
      }
    },
    // 点击就诊人
    openChoose() {
      this.showPopup = true;
    },
    // 修改时间段
    changeSchedule(index) {
      this.currentIndex = index;
    },
    // 确认预约按钮点击触发
    submitData() {
      if (!this.list || this.list.length == 0) {
        this.isHasPatient();
        // Toast({message: "请创建就诊人"});
        return;
      }
      if (!this.chooseItem) {
        Toast({ message: "请选择就诊人" });
        return;
      }
      if (this.currentIndex < 0) {
        Toast({ message: "请选择就诊时间段" });
        return;
      }
      this.showDialog = true;
    },
    // 对话框点击确认时触发
    handleConfirm() {
      this.$router.push("/appointSuccess");
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                console.log(this.chooseItem);
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
              console.log(this.chooseItem);
            }
            this.getSchedules();
            this.isHasPatient();
            sessionStorage.setItem(
              "CurrentJZPerson",
              JSON.stringify(this.chooseItem)
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    getSchedules() {
      for (let i = 0; i < this.doctor.details.length; i++) {
        this.clinicFee = this.doctor.details[i].clinicFee;
      }
    },
    /**
     * 预约挂号-带支付的
     */
    submit() {
      let doctorInfoArr = this.data.dutyType.split("_");
      let visitStart =
        this.data.ClinicDate + " " + doctorInfoArr[doctorInfoArr.length - 2];
      let visitEnd =
        this.data.ClinicDate + " " + doctorInfoArr[doctorInfoArr.length - 1];
      let postData = {
        userid: sessionStorage.getItem("openid"),
        name: this.chooseItem.name,
        dutyId: this.data.dutyId,
        healthId: this.chooseItem.healthId,
        clinicFee: this.data.clinicFee,
        deptCode: this.doctor.bizDeptCode,
        deptName: this.doctor.bizDeptName,
        patid: this.chooseItem.patId,
        doctorCode: this.doctor.bizDoctorId,
        doctorName: this.doctor.bizDoctorName,
        labelType: this.LabelType,
        visitDate: this.data.dutyDate,
        dutyTimeFlag: this.data.dutyTimeFlag,
        visitStart: this.data3105[this.currentIndex].dutyStartTime,
        visitEnd: this.data3105[this.currentIndex].dutyEndTime,
        type: this.$route.query.type,
        covidorderid: this.$route.query.covidorderid,
      };
      this.btnLoading = true;
      this.$http
        .post("/api/wlptappointment/eh3106", postData)
        .then((result) => {
          if (result.data) {
            this.uploadData();
            this.$router.push({
              path: "/appointWxPayPage",
              query: {
                id: result.data,
                price: this.clinicFee,
                type: 0,
              },
            });
          } else {
            Toast({ message: "预约失败,请刷新后重试！" });
          }
          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          if (err.response.data.indexOf("已经预约") != -1) {
            this.showDialog1 = true;
            return;
          }
          if (err.response.data.indexOf("ORA") == -1) {
            // 没有ORA表示不是oracle错误
            Toast({ message: err.response.data });
          } else {
            Toast({ message: "服务异常，请联系管理员" });
          }
        });
      this.showDialog = false;
    },
    chooseJZPerson(item) {
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
    },
    //用卡信息上传
    uploadData() {
      let uploadData = {
        patid: this.chooseItem.patId,
        department: this.LabelType,
        scene: "0101011",
      };
      this.$healthHttpService
        .get("healthapi/api/upload", uploadData)
        .then((res) => {});
    },
    /**
     * 查看我的预约
     */
    selectAppoint() {
      sessionStorage.setItem("activeName", "a");
      this.$router.push("/appointPayments");
    },
    /**
     * 调用3105接口
     */
    get3105() {
      let postData = {
        deptCode: this.doctor.bizDeptCode,
        doctorId: this.doctor.bizDoctorId,
        dutyDate: this.data.dutyDate,
        dutyFlag: this.data.dutyTimeFlag,
        type: this.$route.query.type,
      };
      this.$http.get("/api/wlptappointment/eh3105", postData).then((result) => {
        this.data3105 = result.data;
        this.appointed =
          this.data3105[0].dutyTotalNum - this.data3105[0].dutyRemainedNum;
        console.log(this.appointed);
      });
    },
  },
  mounted() {
    this.getList();
    this.doctor = JSON.parse(decodeURIComponent(this.$route.query.doctor));
    this.data = JSON.parse(decodeURIComponent(this.$route.query.data));
    this.LabelType = this.$route.query.LabelType;
    let sys_config = JSON.parse(window.localStorage.getItem("sysconfig"));
    this.apply_notice = sys_config.find(
      (item) => item.paramKey == "apply_notice"
    );
    this.get3105();
    if (!this.doctor) {
      Toast({ message: "医生已停诊" });
      return;
    }
    this.deptId = this.doctor.DeptCode;
  },
};
</script>

<style scoped>
.d-flex-column {
  margin-top: 0.25rem;
}

.yyqr-uni-list-jzrq {
  justify-content: space-between;
  flex-direction: row;
  font-size: 0.4rem;
}

.schedule {
  width: 2.2rem;
  min-width: 100px;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  color: #b5b5b5;
  padding: 0.1rem 0.1rem;
  border-radius: 5px;
}

.schedule.active {
  background-color: #dbf9ef;
  border: solid 1px #1ad997;
  color: #1ad997;
}

.times {
  display: inline-block;
  margin-top: 0.3rem;
  flex-wrap: wrap;
  text-align: center;
}

.btn-sure {
  width: 9.2rem;
  margin-top: 0.8rem;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #57fdc4, #00d48b);
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}

/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}

.dialog-message {
  font-size: 0.45rem;
  line-height: 26px;
  margin-bottom: 5px;
  padding: 0.2rem 0.8rem;
}

/deep/ .van-button.van-button--default.van-button--large.van-dialog__cancel {
  background-image: linear-gradient(#d2d2d2, #bababa);
  color: #fff;
}

/deep/
  .van-button.van-button--default.van-button--large.van-dialog__confirm.van-hairline--left {
  background-image: linear-gradient(#53fbc1, #00d48a);
  color: #fff;
}

.doctor-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.info-notice {
  margin-top: 0.5rem;
  font-size: 0.4rem;
  color: #1d9ecf;
}
/* /deep/ .van-dialog__header {
  padding-bottom: 0.5rem;
} */
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-indent: 0.8rem;
  text-align: left;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.4rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.4rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.dialog-title {
  margin-bottom: 0.3rem;
}
/deep/ .van-dialog__header {
  padding-bottom: 0;
}
.button-confirm {
  width: 4rem;
  height: 100%;
  font-size: 0.45rem;
}
/deep/ .van-submit-bar__bar {
  padding: 0;
}
.info-notice {
  margin-top: 0.5rem;
  font-size: 0.4rem;
  color: #1d9ecf;
}
.button-confirm {
  width: 4rem;
  height: 100%;
  font-size: 0.45rem;
}
/deep/ .van-submit-bar__bar {
  padding: 0;
}
</style>
